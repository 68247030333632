import React from 'react';
import {
  translate,
  Create,
  Datagrid,
  Edit,
  ReferenceInput,
  EditButton,
  DisabledInput,
  RadioButtonGroupInput,
  Filter,
  SimpleForm,
  FunctionField,
  TextInput,
  BooleanInput,
  required,
  AutocompleteInput,
  Toolbar,
  SaveButton,
  TextField,
  FormDataConsumer,
} from 'react-admin';
import { TimeInput } from 'react-admin-date-inputs';
import List from '../customs/List';
import withStyles from '@material-ui/core/styles/withStyles';
import Icon from '@material-ui/icons/Flag';
import moment from 'moment';
import Poster from '../posters/Poster';
import PosterAr from '../posters/PosterAr';
export const CitiesIcon = Icon;

export const OrdersFilter = (props) => (
  <Filter {...props}>
    <ReferenceInput
      source='city_id'
      reference='cities'
      alwaysOn
      // sort={{ field: 'id', order: 'ASC' }}
    >
      <AutocompleteInput source='name' enableSearch={true} />
    </ReferenceInput>
  </Filter>
);

export const CitiesList = (props) =>
  localStorage.getItem('editorAdmin') != 1 ? (
    <List perPage={25} {...props}>
      {/* {console.log(props)} */}
      <Datagrid>
        <TextField source='name' />
        <FunctionField
          label='Tax'
          render={(record) => `${record.tax_percentage}%`}
        />

        <EditButton />
      </Datagrid>
    </List>
  ) : (
    <List
      perPage={25}
      {...props}
      bulkActionButtons={localStorage.getItem('editorAdmin') != 1 ? '' : false}
    >
      {/* {console.log(props)} */}
      <Datagrid>
        <TextField source='name' />
        <FunctionField
          label='Tax'
          render={(record) => `${record.tax_percentage}%`}
        />

        <EditButton />
      </Datagrid>
    </List>
  );

const createStyles = {
  stock: { width: '5em' },
  price: { width: '5em' },
  width: { width: '5em' },
  widthFormGroup: { display: 'inline-block' },
  height: { width: '5em' },
  heightFormGroup: { display: 'inline-block', marginLeft: 32 },
};

export const CitiesCreate = withStyles(createStyles)(
  ({ classes, ...props }) => (
    <Create {...props}>
      <SimpleForm redirect='list'>
        <DisabledInput className={classes.input} source='name' required />
        <DisabledInput className={classes.input} source='name_ar' />
        <DisabledInput className={classes.input} source='fees' required />
        <TimeInput
          className={classes.input}
          label='Serve Until'
          source='serve_until'
          required
        />
        <PosterAr />
        <Poster />
      </SimpleForm>
    </Create>
  ),
);

const CitiesTitle = translate(({ record, translate }) => (
  <span>
    {translate('Cities.name')} #{record.id}
  </span>
));

const editStyles = {
  ...createStyles,
  comment: {
    maxWidth: '20em',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
};

const CustomToolbar = withStyles(toolbarStyles)((props) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
));

const toolbarStyles = {
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
};

const isCorrectFormat = (dateString, format) => {
  return moment(dateString, format, true).isValid();
};

export const CitiesEdit = withStyles(createStyles)(({ classes, ...props }) => (
  <Edit {...props} title={<CitiesTitle />}>
    <SimpleForm redirect='list' toolbar={<CustomToolbar />}>
      <TextInput
        source='tax_percentage'
        validate={[required()]}
        disabled={localStorage.getItem('editorAdmin') == 1 ? true : false}
      />
      <PosterAr title='Website Arabic Poster' />
      <Poster  title='Website English Poster' />
    </SimpleForm>
  </Edit>
));
