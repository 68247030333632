import React from 'react';
import {AutocompleteInput, BooleanInput, Create, Datagrid, DeleteButton, Edit, EditButton, Filter, FormTab, maxLength, minLength, ReferenceInput, regex, required, SaveButton, SimpleForm, TabbedForm, TextField, TextInput, Toolbar, translate,} from 'react-admin';
import List from '../customs/List';
import withStyles from '@material-ui/core/styles/withStyles';
import Icon from '@material-ui/icons/LocationOn';
import moment from 'moment';
import GoogleMap from 'google-map-react';
import Circle from 'google-map-react';
import MyGreatPlace from './my_great_place';

export const BranchesIcon = Icon;
export const OrdersFilter = (props) => (
    <Filter {...props}>
        <ReferenceInput
            source='city_id'
            reference='cities'
            alwaysOn
            // sort={{ field: 'id', order: 'ASC' }}
        >
            <AutocompleteInput source='name' enableSearch={true}/>
        </ReferenceInput>
    </Filter>
);

export const BranchesList = (props) => (
    <List perPage={25} {...props} filters={<OrdersFilter/>}>
        {/* {console.log(props)} */}
        <Datagrid>
            <TextField source='name'/>
            <TextField source='city_name' sortable={false}/>
            <EditButton/>
            <DeleteButton/>
        </Datagrid>
    </List>
);
const AnyReactComponent = ({text}) => <div>{text}</div>;

const createStyles = {
    stock: {width: '5em'},
    price: {width: '5em'},
    width: {width: '5em'},
    widthFormGroup: {display: 'inline-block'},
    height: {width: '5em'},
    heightFormGroup: {display: 'inline-block', marginLeft: 32},
};

export const BranchesCreate = withStyles(createStyles)(
    ({classes, ...props}) => (
        <Create {...props}>
            <SimpleForm redirect='list'>
                <TextInput
                    className={classes.input}
                    source='name'
                    validate={[required(), minLength(3), maxLength(20)]}
                />
                <TextInput
                    className={classes.input}
                    source='name_ar'
                    validate={[required(), minLength(3), maxLength(20)]}
                />
                <TextInput
                    className={classes.input}
                    source='description'
                    validate={[required(), minLength(3), maxLength(50)]}
                />
                <TextInput
                    className={classes.input}
                    source='description_ar'
                    validate={[required(), minLength(3), maxLength(50)]}
                />
                <TextInput
                    className={classes.input}
                    source='long'
                    validate={[
                        required(),
                        regex(
                            /^(\-?([0-8]?[0-9](\.\d+)?|90(.[0]+)?)\s?)$/,
                            'Must be valid coordinate',
                        ),
                        minLength(10),
                    ]}
                />
                <TextInput
                    className={classes.input}
                    source='lat'
                    validate={[
                        required(),
                        regex(
                            /^(\-?([0-8]?[0-9](\.\d+)?|90(.[0]+)?)\s?)$/,
                            'Must be valid coordinate',
                        ),
                        minLength(10),
                    ]}
                />
                <ReferenceInput
                    source='city_id'
                    reference='cities'
                    // sort={{ field: 'id', order: 'ASC' }}
                >
                    <AutocompleteInput source='name' enableSearch={true}/>
                </ReferenceInput>
            </SimpleForm>
        </Create>
    ),
);

const BranchesTitle = translate(({record, translate}) => (
    <span>
    {translate('Branches.name')} #{record.id}
  </span>
));

const editStyles = {
    ...createStyles,
    comment: {
        maxWidth: '20em',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
};

const CustomToolbar = withStyles(toolbarStyles)((props) => (
    <Toolbar {...props}>
        <SaveButton/>
    </Toolbar>
));

const toolbarStyles = {
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
};

const isCorrectFormat = (dateString, format) => {
    return moment(dateString, format, true).isValid();
};

const defaultProps = {
    center: {
        lat: 30.0220843798619,
        lng: 31.44467825441856,
    },
    language: 'ar-EG',
    region: 'ar-EG',
    zoom: 14,
};

const ApproveButton = ({record2, record}) => {
    return (
        <div style={{height: '100vh', width: '100%'}}>
            <GoogleMap
                bootstrapURLKeys={{key: 'AIzaSyDU0Kl3nBZ40cCj5-hUl5K_-HZA6uZdhg8'}}
                defaultCenter={{
                    lat: parseFloat(record.lat),
                    lng: parseFloat(record.long),
                }}
                defaultZoom={defaultProps.zoom}
            >
                <Circle
                    bootstrapURLKeys={{key: 'AIzaSyDU0Kl3nBZ40cCj5-hUl5K_-HZA6uZdhg8'}}
                    center={{lat: parseFloat(record.lat), lng: parseFloat(record.long)}}
                    radius={50000}
                    options={{
                        strokeColor: '#ff0000',
                    }}
                />
                <MyGreatPlace
                    circle={{
                        radius: 3000,
                        options: {
                            strokeColor: '#ff0000',
                        },
                    }}
                    lat={parseFloat(record.lat)}
                    lng={parseFloat(record.long)}
                    text={record.name}
                />
            </GoogleMap>
        </div>
    );
};
const number = (value) =>
    value && isNaN(Number(value)) ? 'Must be a number' : undefined;
export const BranchesEdit = withStyles(editStyles)(({classes, ...props}) => (
    <Edit {...props} title={<BranchesTitle/>}>
        <TabbedForm redirect='list' toolbar={<CustomToolbar/>}>
            <FormTab label='Basic Info'>
                <TextInput
                    className={classes.input}
                    source='name'
                    validate={[required(), minLength(3), maxLength(20)]}
                />
                <TextInput
                    className={classes.input}
                    source='name_ar'
                    validate={[required(), minLength(3), maxLength(20)]}
                />
                <TextInput
                    className={classes.input}
                    source='description'
                    validate={[required(), minLength(3), maxLength(50)]}
                />
                <TextInput
                    className={classes.input}
                    source='description_ar'
                    validate={[required(), minLength(3), maxLength(50)]}
                />
                <ReferenceInput
                    source='city_id'
                    reference='cities'
                    // sort={{ field: 'id', order: 'ASC' }}
                >
                    <AutocompleteInput source='name' enableSearch={true}/>
                </ReferenceInput>
                <BooleanInput
                    style={{marginTop: '30px'}}
                    label='Stop Receiving Orders'
                    source='stop_receiving_orders'
                />
                <TextInput
                    source='ar_stop_receiving'
                    label='Stop Receiving Message (Arabic)'
                    disabled={localStorage.getItem('editorAdmin') == 1 ? true : false}
                />
                <TextInput
                    source='en_stop_receiving'
                    label='Stop Receiving Message (English)'
                    disabled={localStorage.getItem('editorAdmin') == 1 ? true : false}
                />
            </FormTab>
            <FormTab label='Location Info'>
                <TextInput
                    className={classes.input}
                    source='long'
                    validate={[
                        required(),
                        minLength(3),
                        regex(
                            /^(\-?([0-8]?[0-9](\.\d+)?|90(.[0]+)?)\s?)$/,
                            'Must be valid coordinate',
                        ),
                        minLength(10),
                    ]}
                />
                <TextInput
                    className={classes.input}
                    source='lat'
                    validate={[
                        required(),
                        regex(
                            /^(\-?([0-8]?[0-9](\.\d+)?|90(.[0]+)?)\s?)$/,
                            'Must be valid coordinate',
                        ),
                        minLength(5),
                    ]}
                />
                <TextInput
                    className={classes.input}
                    source='radius'
                    validate={[required()]}
                />
                <ApproveButton source='tax_percentage'/>
            </FormTab>
            <FormTab label='Payment Info'>
                <BooleanInput
                    label='Accpet Visa Payment For IOS'
                    source='enable_payment_ios'
                />
                <BooleanInput
                    label='Accpet Visa Payment For Android'
                    source='enable_payment_android'
                />
            </FormTab>
        </TabbedForm>
    </Edit>
));
